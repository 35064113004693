'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.units.factory:UnitDevicesManager

 # @description

###
angular
  .module 'mundoAdmin.units'
  .factory 'UnitDevicesManager', [
    '$mdDialog'
    'RestUtils'
    'DeviceTypeManager'
    'DeviceManager'
    'MundoDefaultManager'
    '$translate'
    (
      $mdDialog
      RestUtils
      DeviceTypeManager
      DeviceManager
      MundoDefaultManager
      $translate
    )->
      UnitDevicesManagerBase = new MundoDefaultManager()
      UnitDevicesManagerBase.setUrl('devices')
      UnitDevicesManagerBase.setAutoTenant()
      UnitDevicesManagerBase.setNewObject(['device', 'deviceTypeProfile', 'managed', 'installed'])
      UnitDevicesManagerBase.setParentUrl('units')

      UnitDevicesManagerBase.getEditForm = (data) ->
        devices = DeviceManager.getFullList({'sort' : 'label,ASC'}).$object
        [
          key: 'device'
          name: 'device'
          type: 'select'
          defaultValue : data.device.id
          templateOptions:
            label: 'device'
            placeholder: 'device'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: devices
        ,
          key: 'deviceTypeProfile'
          name: 'deviceTypeProfile'
          type: 'select'
          defaultValue : data.deviceTypeProfile
          templateOptions:
            label: 'Device Type Profile'
            placeholder: 'deviceTypeProfile'
            required: true
            labelProp: "label"
            valueProp: "code"
            options: DeviceTypeManager.getProfilesByType(data.device.deviceType)
          data:{
            devices: devices
          }
          controller: [
            '$scope'
            'DeviceTypeManager'
            ($scope, DeviceTypeManager)->
              $scope.$watch 'model.device', (newValue, oldValue, theScope) ->
                device = _.findWhere $scope.options.data.devices, {id: newValue}
                if device?
                  $scope.to.options = DeviceTypeManager.getProfilesByType(device.deviceType)
          ]
        ,
          key: 'managed'
          name: 'managed'
          type: 'checkbox'
          defaultValue: data.managed
          templateOptions:
            label: $translate.instant('app.managed')
        ,
          key: 'installed'
          name: 'installed'
          type: 'checkbox'
          defaultValue: data.installed
          templateOptions:
            label: $translate.instant('app.installation')
        ]

      UnitDevicesManagerBase
  ]
